import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
        <p>At SelfieToPassport, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our website and services.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">2. Information We Collect</h2>
        <p>We collect the following types of information:</p>
        <ul className="list-disc list-inside ml-4 mt-2">
          <li>Personal information (e.g., name, email address) that you voluntarily provide when creating an account or using our services.</li>
          <li>Photos that you upload to our platform for processing.</li>
          <li>Usage data, including information on how you interact with our website and services.</li>
          <li>Device information, such as your IP address, browser type, and operating system.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">3. How We Use Your Information</h2>
        <p>We use the collected information for the following purposes:</p>
        <ul className="list-disc list-inside ml-4 mt-2">
          <li>To provide and maintain our services, including processing your photos.</li>
          <li>To improve and personalize your experience on our platform.</li>
          <li>To communicate with you about your account or our services.</li>
          <li>To detect, prevent, and address technical issues or fraudulent activities.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">4. Data Storage and Security</h2>
        <p>We implement appropriate technical and organizational measures to protect your personal information against unauthorized or unlawful processing, accidental loss, destruction, or damage. However, please note that no method of transmission over the Internet or electronic storage is 100% secure.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">5. Data Retention</h2>
        <p>We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">6. Your Data Protection Rights</h2>
        <p>Depending on your location, you may have the following rights regarding your personal information:</p>
        <ul className="list-disc list-inside ml-4 mt-2">
          <li>The right to access, update, or delete your information.</li>
          <li>The right to rectification if your information is inaccurate or incomplete.</li>
          <li>The right to object to our processing of your personal data.</li>
          <li>The right of restriction, meaning you can request that we restrict the processing of your personal information.</li>
          <li>The right to data portability.</li>
          <li>The right to withdraw consent at any time for any data processing we carried out based on your consent.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">7. Third-Party Services</h2>
        <p>We may employ third-party companies and individuals to facilitate our services, provide services on our behalf, perform service-related tasks, or assist us in analyzing how our services are used. These third parties have access to your personal information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">8. Children's Privacy</h2>
        <p>Our services are not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If you become aware that a child has provided us with personal information, please contact us. If we become aware that we have collected personal information from children without verification of parental consent, we take steps to remove that information from our servers.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">9. Changes to This Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the bottom of this Privacy Policy.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">10. Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us:</p>
        <address className="mt-2">
          SelfieToPassport<br />
          Email: privacy@SelfieToPassport.com<br />
          Address: 123 Photo Lane, Snapshot City, 12345
        </address>
      </section>

      <footer className="mt-12 text-sm text-gray-600">
        <p>Last updated: {new Date().toLocaleDateString()}</p>
      </footer>
    </div>
  );
}

export default PrivacyPolicy;