import React from 'react';

export default function TermsOfUse() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Terms of Use</h1>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
        <p>By accessing and using SelfieToPassport (the "Service"), you agree to be bound by these Terms of Use. If you do not agree to these terms, please do not use our Service.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">2. Description of Service</h2>
        <p>SelfieToPassport provides an online platform for creating and editing passport and ID photos. We do not guarantee that photos created using our Service will be accepted by all authorities.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">3. User Responsibilities</h2>
        <p>You are responsible for ensuring that the photos you create comply with the requirements of the relevant authorities. You agree not to use our Service for any unlawful purpose or in any way that could damage or impair the Service.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">4. User Accounts</h2>
        <p>To access certain features of the Service, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">5. Intellectual Property</h2>
        <p>The content, features, and functionality of the Service are owned by SelfieToPassport and are protected by international copyright, trademark, and other intellectual property laws. You retain ownership of any photos you upload to our Service, but grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, and process your photos for the purpose of providing our Service.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">6. Privacy</h2>
        <p>Your use of the Service is also governed by our Privacy Policy. Please review our Privacy Policy, which also governs the Service and informs users of our data collection practices.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">7. Disclaimer of Warranties</h2>
        <p>The Service is provided "as is" and "as available" without any warranties of any kind, either express or implied. We do not warrant that the Service will be uninterrupted or error-free.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">8. Limitation of Liability</h2>
        <p>SelfieToPassport shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of or inability to use the Service.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">9. Indemnification</h2>
        <p>You agree to indemnify and hold SelfieToPassport and its affiliates, officers, agents, and employees harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of the Service, your violation of these Terms of Use, or your violation of any rights of another.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">10. Termination</h2>
        <p>We reserve the right to terminate or suspend your account and access to the Service at our sole discretion, without notice, for conduct that we believe violates these Terms of Use or is harmful to other users of the Service, us, or third parties, or for any other reason.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">11. Changes to Terms</h2>
        <p>We reserve the right to modify these Terms of Use at any time. We will provide notice of any material changes by posting the new Terms of Use on the Service. Your continued use of the Service after any such changes constitutes your acceptance of the new Terms of Use.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">12. Governing Law</h2>
        <p>These Terms of Use shall be governed by and construed in accordance with the laws of Wyoming (USA), without regard to its conflict of law provisions.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">13. Dispute Resolution</h2>
        <p>Any dispute arising from or relating to the subject matter of these Terms of Use shall be finally settled by arbitration in Wyoming (USA), using the English language in accordance with the Arbitration Rules and Procedures of [Arbitration Organization] then in effect, by one commercial arbitrator with substantial experience in resolving intellectual property and commercial contract disputes.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">14. Severability</h2>
        <p>If any provision of these Terms of Use is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that these Terms of Use shall otherwise remain in full force and effect and enforceable.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">15. Entire Agreement</h2>
        <p>These Terms of Use constitute the entire agreement between you and SelfieToPassport with respect to the Service, and supersede all prior or contemporaneous communications and proposals, whether oral or written, between you and SelfieToPassport with respect to the Service.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">16. Contact Information</h2>
        <p>If you have any questions about these Terms of Use, please contact us at:</p>
        <address className="mt-2">
          SelfieToPassport<br />
          Email: support@SelfieToPassport.com<br />
          Address: 123 Photo Lane, Snapshot City, 12345
        </address>
      </section>

      <footer className="mt-12 text-sm text-gray-600">
        <p>Last updated: {new Date().toLocaleDateString()}</p>
      </footer>
    </div>
  );
}