'use client'

import React, { useState, useCallback, useRef, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { motion, AnimatePresence } from 'framer-motion'
import { Upload, Zap, Loader, ZoomIn, ZoomOut, ArrowUp, ArrowDown, ArrowLeft, ArrowRight, Check, Download } from 'lucide-react'

const countries = [
  { 
    code: 'US', 
    name: 'United States', 
    flag: '🇺🇸', 
    size: { width: 2, height: 2, unit: 'in' },
    faceHeight: 0.69,
    headTop: 0.2,
    headBottom: 0.8
  },
  { 
    code: 'EU', 
    name: 'European Union', 
    flag: '🇪🇺', 
    size: { width: 35, height: 45, unit: 'mm' },
    faceHeight: 0.7,
    headTop: 0.1,
    headBottom: 0.9
  },
  { 
    code: 'AU', 
    name: 'Australia', 
    flag: '🇦🇺', 
    size: { width: 35, height: 45, unit: 'mm' },
    faceHeight: 0.75,
    headTop: 0.15,
    headBottom: 0.85
  },
  { 
    code: 'JP', 
    name: 'Japan', 
    flag: '🇯🇵', 
    size: { width: 35, height: 45, unit: 'mm' },
    faceHeight: 0.7,
    headTop: 0.2,
    headBottom: 0.8
  },
  { 
    code: 'CA', 
    name: 'Canada', 
    flag: '🇨🇦', 
    size: { width: 50, height: 70, unit: 'mm' },
    faceHeight: 0.7,
    headTop: 0.15,
    headBottom: 0.85
  },
]

export default function Landing() {
  const [file, setFile] = useState(null)
  const [processedImage, setProcessedImage] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const [zoom, setZoom] = useState(1)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [isEditing, setIsEditing] = useState(false)
  const canvasRef = useRef(null)
  const imageRef = useRef(null)

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0])
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const processPhoto = async (imageFile) => {
    if (!imageFile) return

    setIsProcessing(true)
    const formData = new FormData()
    formData.append('image', imageFile)

    try {
      const response = await fetch('https://d1xvi0edwcbd81.cloudfront.net/process-image', {
        method: 'POST',
        body: formData,
      })

      if (!response.ok) {
        throw new Error('Image processing failed')
      }

      const blob = await response.blob()
      const imageUrl = URL.createObjectURL(blob)
      setProcessedImage(imageUrl)
    } catch (error) {
      console.error('Error processing image:', error)
      // Handle error (e.g., show error message to user)
    } finally {
      setIsProcessing(false)
    }
  }

  const handleZoom = (delta) => {
    setZoom(prevZoom => Math.max(0.5, Math.min(3, prevZoom + delta)))
  }

  const handleMove = (dx, dy) => {
    setPosition(prev => ({
      x: prev.x + dx,
      y: prev.y + dy
    }))
  }

  const renderImage = (showLines = true) => {
    if (!canvasRef.current || !imageRef.current || !selectedCountry) return

    const canvas = canvasRef.current
    const ctx = canvas.getContext('2d')
    const img = imageRef.current

    const aspectRatio = selectedCountry.size.width / selectedCountry.size.height
    canvas.width = 300 // Fixed width
    canvas.height = canvas.width / aspectRatio

    ctx.clearRect(0, 0, canvas.width, canvas.height)

    // Calculate the scale to fit the image within the canvas
    const scale = Math.min(canvas.width / img.width, canvas.height / img.height)
    const scaledWidth = img.width * scale * zoom
    const scaledHeight = img.height * scale * zoom

    // Calculate drawing position
    const drawX = (canvas.width - scaledWidth) / 2 + position.x
    const drawY = (canvas.height - scaledHeight) / 2 + position.y

    // Draw image
    ctx.drawImage(img, drawX, drawY, scaledWidth, scaledHeight)

    if (showLines) {
      // Draw guide lines
      ctx.strokeStyle = 'rgba(255, 0, 0, 0.7)'
      ctx.lineWidth = 2
      ctx.setLineDash([5, 5])
      
      const topY = canvas.height * selectedCountry.headTop
      const bottomY = canvas.height * selectedCountry.headBottom

      ctx.beginPath()
      ctx.moveTo(0, topY)
      ctx.lineTo(canvas.width, topY)
      ctx.moveTo(0, bottomY)
      ctx.lineTo(canvas.width, bottomY)
      ctx.stroke()
    }
  }

  useEffect(() => {
    if (file) {
      processPhoto(file)
    }
  }, [file])

  useEffect(() => {
    if (processedImage && selectedCountry) {
      const img = new Image()
      img.onload = () => {
        imageRef.current = img
        renderImage()
      }
      img.src = processedImage
    }
  }, [processedImage, selectedCountry, zoom, position])

  const handleContinue = () => {
    setIsEditing(true)
    setZoom(1)
    setPosition({ x: 0, y: 0 })
  }

  const handleDownload = () => {
    if (canvasRef.current && imageRef.current && selectedCountry) {
      renderImage(false) // Render without guide lines
      const link = document.createElement('a')
      link.download = 'passport_photo.png'
      link.href = canvasRef.current.toDataURL()
      link.click()
      renderImage(true) // Re-render with guide lines for display
    }
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 flex flex-col">
      <header className="w-full p-4 flex justify-between items-center">
        <h1 className="text-2xl font-bold text-indigo-700">📷 Selfie To Passport</h1>
{/*        <button className="bg-indigo-600 text-white px-4 py-2 rounded-full hover:bg-indigo-700 transition-colors duration-300 flex items-center">
          <Zap className="mr-2" size={18} />
          Unlock All Features
        </button>*/}
      </header>

      <main className="flex-grow flex flex-col md:flex-row items-center justify-center p-8 gap-8">
        <section className="w-full md:w-1/2 max-w-md">
          {file ? (
            <div className="space-y-4">
              <div className="relative rounded-lg overflow-hidden aspect-[3/4]">
                {processedImage ? (
                  isEditing ? (
                    <canvas
                      ref={canvasRef}
                      className="w-full h-full object-contain"
                    />
                  ) : (
                    <img
                      src={processedImage}
                      alt="Processed photo"
                      className="w-full h-full object-contain"
                    />
                  )
                ) : (
                  <img
                    src={URL.createObjectURL(file)}
                    alt="Uploaded photo"
                    className="w-full h-full object-cover"
                  />
                )}
                <AnimatePresence>
                  {isProcessing && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center"
                    >
                      <Loader className="w-16 h-16 text-white animate-spin" />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
              {isEditing && (
                <div className="space-y-4">
                  <div className="flex justify-center space-x-2">
                    <button onClick={() => handleZoom(0.1)} className="p-2 bg-indigo-600 text-white rounded-full">
                      <ZoomIn size={20} />
                    </button>
                    <button onClick={() => handleZoom(-0.1)} className="p-2 bg-indigo-600 text-white rounded-full">
                      <ZoomOut size={20} />
                    </button>
                  </div>
                  <div className="grid grid-cols-3 gap-2">
                    <button onClick={() => handleMove(-10, -10)} className="p-2 bg-indigo-600 text-white rounded-full">
                      <ArrowUp size={20} className="transform -rotate-45" />
                    </button>
                    <button onClick={() => handleMove(0, -10)} className="p-2 bg-indigo-600 text-white rounded-full">
                      <ArrowUp size={20} />
                    </button>
                    <button onClick={() => handleMove(10, -10)} className="p-2 bg-indigo-600 text-white rounded-full">
                      <ArrowUp size={20} className="transform rotate-45" />
                    </button>
                    <button onClick={() => handleMove(-10, 0)} className="p-2 bg-indigo-600 text-white rounded-full">
                      <ArrowLeft size={20} />
                    </button>
                    <button onClick={() => setPosition({ x: 0, y: 0 })} className="p-2 bg-indigo-600 text-white rounded-full">
                      <Check size={20} />
                    </button>
                    <button onClick={() => handleMove(10, 0)} className="p-2 bg-indigo-600 text-white rounded-full">
                      <ArrowRight size={20} />
                    </button>
                    <button onClick={() => handleMove(-10, 10)} className="p-2 bg-indigo-600 text-white rounded-full">
                      <ArrowDown size={20} className="transform rotate-45" />
                    </button>
                    <button onClick={() => handleMove(0, 10)} className="p-2 bg-indigo-600 text-white rounded-full">
                      <ArrowDown size={20} />
                    </button>
                    <button onClick={() => handleMove(10, 10)} className="p-2 bg-indigo-600 text-white rounded-full">
                      <ArrowDown size={20} className="transform -rotate-45" />
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div
              {...getRootProps()}
              className={`border-4 border-dashed rounded-lg p-8 text-center cursor-pointer transition-colors duration-300 ${
                isDragActive ? 'border-indigo-400 bg-indigo-100' : 'border-gray-300 hover:border-indigo-400'
              }`}
            >
              <input {...getInputProps()} />
              <Upload className="mx-auto text-indigo-500 mb-4" size={48} />
              <p className="text-gray-600">Drag & drop your photo here, or click to select</p>
            </div>
          )}
        </section>

        <section className="w-full md:w-1/2 max-w-md">
          <AnimatePresence mode="wait">
            {isProcessing ? (
              <motion.div
                key="processing"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
                className="flex flex-col items-center justify-center space-y-4"
              >
                <p className="text-xl font-semibold text-indigo-800">Processing your photo...</p>
                <p className="text-gray-600">This may take a few moments</p>
              </motion.div>
            ) : processedImage ? (
              isEditing ? (
                <motion.div
                  key="editing"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.5 }}
                  className="space-y-6"
                >
                  <h2 className="text-3xl font-bold text-indigo-800 text-center">Fine-tune Your Photo</h2>
                  <p className="text-lg text-gray-700 text-center">
                    Use the controls to adjust your photo. Make sure your head is between the red guide lines.
                  </p>
                  <div className="bg-white p-4 rounded-lg shadow-md">
                    <h3 className="text-lg font-semibold text-indigo-800 mb-2">Guide Lines Explanation</h3>
                    <p className="text-gray-700">
                      The red dashed lines indicate where the top and bottom of your head should be positioned. 
                      Adjust your photo so that your head fits between these lines for the perfect passport photo.
                    </p>
                  </div>
                  <div className="text-center text-gray-700">
                    <p>Photo size: {selectedCountry.size.width}x{selectedCountry.size.height} {selectedCountry.size.unit}</p>
                    <p>Face height: {selectedCountry.faceHeight * 100}% of image height</p>
                    <p>Head position: {selectedCountry.headTop * 100}% - {selectedCountry.headBottom *   100}% from top</p>
                  </div>
                  <button
                    onClick={handleDownload}
                    className="w-full bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 transition-colors duration-300 flex items-center justify-center"
                  >
                    <Download className="mr-2" size={20} />
                    Finish and Download
                  </button>
                </motion.div>
              ) : (
                <motion.div
                  key="country-selection"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.5 }}
                  className="space-y-6"
                >
                  <h2 className="text-3xl font-bold text-indigo-800 text-center">Almost there!</h2>
                  <p className="text-xl text-gray-700 text-center">Hooray, we removed the background!</p>
                  <p className="text-lg text-gray-700 text-center">Next, choose the country so we can create the right size for your photo:</p>
                  <div className="flex flex-wrap justify-center gap-4">
                    {countries.map((country) => (
                      <button
                        key={country.code}
                        onClick={() => setSelectedCountry(country)}
                        className={`px-4 py-2 rounded-full text-lg ${
                          selectedCountry?.code === country.code
                            ? 'bg-indigo-600 text-white'
                            : 'bg-white text-indigo-600 hover:bg-indigo-100'
                        } transition-colors duration-300`}
                      >
                        {country.flag} {country.name}
                      </button>
                    ))}
                  </div>
                  {selectedCountry && (
                    <motion.div
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      className="text-center space-y-4"
                    >
                      <div className="text-gray-700">
                        <p>Photo size: {selectedCountry.size.width}x{selectedCountry.size.height} {selectedCountry.size.unit}</p>
                        <p>Face height: {selectedCountry.faceHeight * 100}% of image height</p>
                        <p>Head position: {selectedCountry.headTop * 100}% - {selectedCountry.headBottom * 100}% from top</p>
                      </div>
                      <button
                        onClick={handleContinue}
                        className="w-full bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 transition-colors duration-300"
                      >
                        Continue
                      </button>
                    </motion.div>
                  )}
                </motion.div>
              )
            ) : (
              <motion.div
                key="initial-content"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
                className="space-y-6"
              >
                <motion.h2
                  className="text-3xl font-bold text-indigo-800"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2, duration: 0.5 }}
                >
                  Save Money, Skip the Photographer
                </motion.h2>
                <motion.p
                  className="text-lg text-gray-700"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.4, duration: 0.5 }}
                >
                  Why spend on expensive studio sessions? Create perfect passport or visa photos at home with our app. Get the right measurements, background, and lighting without the hefty price tag.
                </motion.p>
                <motion.ul
                  className="list-disc list-inside text-gray-700 space-y-2"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.6, duration: 0.5 }}
                >
                  <li>Automatic background removal and replacement</li>
                  <li>Perfect sizing for any country's requirements</li>
                  <li>Intelligent lighting adjustment</li>
                  <li>Instant digital delivery</li>
                </motion.ul>
              </motion.div>
            )}
          </AnimatePresence>
        </section>
      </main>
      <footer className="py-4 text-center">
        <div className="flex justify-center space-x-4">
          <a href="/terms-of-use" target="_blank" className="text-indigo-600 hover:underline">Terms of Use</a>
          <a href="/privacy" target="_blank" className="text-indigo-600 hover:underline">Privacy Policy</a>
        </div>
      </footer>
    </div>
  )
}